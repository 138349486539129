import React from "react"
import styled from "styled-components"
import {graphql, useStaticQuery} from 'gatsby'
import {GatsbyImage, getImage} from "gatsby-plugin-image"

const HeaderStyled = styled.div`
    position: relative;
    pointer-events:none;  
    padding-top: 2rem;
`

const PageHeader = () => {

    const data = useStaticQuery(graphql`
        query {
            HeaderImageXs: file(relativePath: { eq: "TnC/bg-tnc-header-576.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 575, quality: 100)
                }
            }
            
            HeaderImageSm: file(relativePath: { eq: "TnC/bg-tnc-header-991.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 991, quality: 100)
                }
            }
            
            HeaderImageLg: file(relativePath: { eq: "TnC/bg-tnc-header-1200.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 1200, quality: 100)
                }
            }
            
            HeaderImageXl: file(relativePath: { eq: "TnC/bg-tnc-header-1920.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 1920, quality: 100)
                }
            }
        }
    `)


    return(
        <HeaderStyled>
            <GatsbyImage placeholder="none"
                fluid={data.HeaderImageXs}

                alt=""
                title=""
                className="img-fluid d-block d-sm-none"
            />
            <GatsbyImage placeholder="none"
                fluid={data.HeaderImageSm}

                alt=""
                title=""
                className="img-fluid d-none d-sm-block d-md-none"
            />
            <GatsbyImage placeholder="none"
                fluid={data.HeaderImageLg}

                alt=""
                title=""
                className="img-fluid d-none d-md-block d-xl-none"
            />
            <GatsbyImage placeholder="none"
                fluid={data.HeaderImageXl}

                alt=""
                title=""
                className="img-fluid d-none d-xl-block"
            />
        </HeaderStyled>
    ) 
}

export default PageHeader