import React, {Component} from "react"
import styled from "styled-components"
import {Row, Col, Container} from 'reactstrap'
import {graphql, StaticQuery} from 'gatsby'

import PageHeader from "components/shared/PageHeader"
import SEO from "utils/Seo"
import Layout from "components/Layout/Layout"
import HeadingSpecial from "components/shared/HeadingSpecial"
import {media} from "utils/Media"

const TermsContent = styled.div`
    h3 {
        color: ${props => props.theme.colors.navy};
        text-align: center;
    }
    
    a {
        font-weight: bold;
        color: ${props => props.theme.colors.navy};
    }
`

const Clouds = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(${props => props.CloudsXs}) no-repeat right top;
    background-size: 100%;
    z-index: 2;
    
    @media ${media.sm} {
        background: url(${props => props.CloudsSm}) no-repeat right center;
        background-size: contain;
        /*height: 700px;
        margin: -4rem 0 0;*/
    }
    
    @media ${media.md} {
        /*height: 750px;
        margin: -4rem 0 0;*/
        background: url(${props => props.CloudsMd}) no-repeat right center;
        background-size: contain;
    }
    
    @media ${media.lg} {
        background: url(${props => props.CloudsLg}) no-repeat right center;
        background-size: cover;
       /* height: 750px;
        margin: 0;*/
    }
    
    @media ${media.xl} {
        background: url(${props => props.CloudsXl}) no-repeat right center;
        background-size: 100%;
        /*height: 1025px;
        margin: -4rem 0 0;    */
    }
    
    h1, h2 {
        font-family: ${props => props.theme.font.family.special};
        color: ${props => props.theme.colors.navy};
        margin: 2rem 0 1rem;
        display: flex;
        width: 100%;
        align-items: center;
    }

`

const Query = () => (
    <StaticQuery
        query={graphql`
            query {                
                CloudsXs: file(relativePath: { eq: "HomePage/clouds-320.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 320, quality: 100, placeholder: NONE
                            formats: [AUTO, WEBP, AVIF])
                    }
                }
                
                CloudsSm: file(relativePath: { eq: "HomePage/clouds-768.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 768, quality: 100, placeholder: NONE
                            formats: [AUTO, WEBP, AVIF])
                    }
                }
                
                CloudsMd: file(relativePath: { eq: "HomePage/clouds-991.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100, placeholder: NONE
                            formats: [AUTO, WEBP, AVIF])
                    }
                }
                
                CloudsLg: file(relativePath: { eq: "HomePage/clouds-1200.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, quality: 100, placeholder: NONE
                            formats: [AUTO, WEBP, AVIF])
                    }
                }
                
                CloudsXl: file(relativePath: { eq: "HomePage/clouds-1920.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100, placeholder: NONE
                            formats: [AUTO, WEBP, AVIF])
                    }
                }
                
                CloudsReversedSm: file(relativePath: { eq: "Article1/clouds-top-991.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100, placeholder: NONE
                            formats: [AUTO, WEBP, AVIF])
                    }
                }
                
                CloudsReversedLg: file(relativePath: { eq: "Article1/clouds-top-1920.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100, placeholder: NONE
                            formats: [AUTO, WEBP, AVIF])
                    }
                }
            }
		`}
        render={data => (
            <TnC data={data}/>
        )}
    />
)

class TnC extends Component {
    constructor(props) {
        super(props);

        this.pageData = {
            pageName: "TnC",
            siteName: "frozeneducation",
            country: "uk",
            region: "emea"
        }

        this.state = {
            activeId: "",
            contentHeight: 0
        }
    }

    componentDidMount() {
        // if (typeof window !== 'undefined' && typeof window.utag !== 'undefined') {
        //     // Tealium
        //     window.utag.view(this.pageData)
        // }
    }

    render() {
        return (
            <Layout pageName="TnC">
                <SEO slug="termsandconditions" title="Terms and Conditions"/>
                
                <PageHeader />

                <TermsContent>
                    <Clouds
                        CloudsXs={this.props.data.CloudsXs.childImageSharp.gatsbyImageData.images.fallback.src}
                        CloudsSm={this.props.data.CloudsSm.childImageSharp.gatsbyImageData.images.fallback.src}
                        CloudsMd={this.props.data.CloudsMd.childImageSharp.gatsbyImageData.images.fallback.src}
                        CloudsLg={this.props.data.CloudsLg.childImageSharp.gatsbyImageData.images.fallback.src}
                        CloudsXl={this.props.data.CloudsXl.childImageSharp.gatsbyImageData.images.fallback.src}
                    >
                        <Container fluid={true}>
                            <Container>
                                {/*<ScrollAnimation animateOnce={true} delay={0} animateIn="fadeIn">*/}
                                    <HeadingSpecial headingTag="h1"
                                                    title="Terms & Conditions"/>
                                    <Row>
                                        <Col xs={12} lg={6}>
                                            <h2>Groups</h2>
                                            <p>Group sales currently available on selected seats and performances from 22 September 2021 until 30 September 2022, excluding school holiday periods and additional black-out dates that may apply as determined by Disney in its sole discretion. Valid for Wednesday and Thursday evenings and Thursday matinee performances only. For Groups from 9 to 19 people, performances from 22 September 2021, eligible Stalls and Royal Circle from £59.50 - £79.50 reduced to £49.50; eligible Grand Circle tickets from £49.50 - £59.50 reduced to £39.50. Minimum group size 9. For Groups over 20 people, performances from 22 September 2021, eligible Stalls and Royal Circle tickets from £59.50 - £79.50 reduced to £45.00; eligible Grand Circle tickets from £49.50 - £59.50 reduced to £35.00. Minimum group size 20.</p>

                                            <p>Not all seats are discounted. Prices may vary on selected performances. Group Tickets purchased shall not be offered for re-sale via unauthorised secondary ticket sites or via social media channels to individual members, unless otherwise agreed in writing by The Walt Disney Company. Offer is subject to availability and may be revoked at any time. All sales final. No refunds or exchanges permitted. Prices, dates, times and cast subject to change without notice. Once issued, tickets cannot be replaced if lost, stolen or destroyed and is only valid for the event for which it is issued. The unlawful resale or attempted resale of the ticket is grounds for seizure and cancellation without compensation. Holder agrees, by the use of the ticket, not to transmit or aid in transmitting any description, account, picture, or reproduction of the show. Holder grants permission to Disney to use the holder’s image or likeness in connection with any live or recorded video or other transmission of the show. Children under the age of 3 will not be admitted to the theatre. All persons aged 16 or under must be accompanied by an adult and may not sit on their own in the auditorium. ©Disney</p>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <h2>Education</h2>
                                            <p>Education group sales currently available on selected seats and performances from 21 October 2021 until 30 September 2022, excluding school holiday periods and additional black-out dates that may apply as determined by Disney in its sole discretion. Valid for Wednesday and Thursday evenings and Thursday matinee performances only. Rate applies for groups of 10+ (primary or secondary school pupils up to and including Year 11). Groups pay £29.50 per ticket in the Stalls & Royal Circle and £22.50 per ticket in Grand Circle for the same performance. For every ten (10) paid tickets, Group receives one (1) free ticket for use by a teacher. Education group tickets purchased shall not be offered for re-sale via unauthorised secondary ticket sites or via social media channels to individual members, unless otherwise agreed in writing by The Walt Disney Company and all attendees must be from the same education group (we reserve the right to verify these details at the theatre). Offer is subject to availability and may be revoked at any time. All sales final. No refunds or exchanges permitted. Prices, dates, times and cast subject to change without notice. Once issued, tickets cannot be replaced if lost, stolen or destroyed and is only valid for the event for which it is issued. The unlawful resale or attempted resale of the ticket is grounds for seizure and cancellation without compensation. Holder agrees, by the use of the ticket, not to transmit or aid in transmitting any description, account, picture, or reproduction of the show. Holder grants permission to Disney to use the holder’s image or likeness in connection with any live or recorded video or other transmission of the show. Children under the age of 3 will not be admitted to the theatre. All persons aged 16 or under must be accompanied by an adult and may not sit on their own in the auditorium. ©Disney</p>
                                        </Col>
                                    </Row>

                                {/*</ScrollAnimation>*/}
                            </Container>
                        </Container>
                    </Clouds>


                </TermsContent>

            </Layout>
        )
    }
}

export default Query